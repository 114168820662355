/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Customer_vacancy_jobs_aggregate($limit: Int, $orderBy: [typeGQLcustomer_vacancy_jobs_order_by!], $offset: Int, $where: typeGQLcustomer_vacancy_jobs_bool_exp, $customerVacancyJobsAggregateWhere2: typeGQLcustomer_vacancy_jobs_bool_exp) {\n  customer_vacancy_jobs_aggregate(where: $customerVacancyJobsAggregateWhere2) {\n    aggregate {\n      count\n    }\n  }\n  customer_vacancy_jobs(limit: $limit, order_by: $orderBy, offset: $offset, where: $where) {\n    id\n    title\n    state\n    city\n    status\n    types_hiring {\n      title\n    }\n    types_working {\n      title\n    }\n    salary_min\n    salary_max\n    date_published\n  }\n}\n\n": types.Customer_vacancy_jobs_aggregateDocument,
    "\n  query Customer_zones($where: typeGQLcustomer_zones_bool_exp) {\n    customer_zones(where: $where) {\n      id\n      title\n    }\n  }\n": types.Customer_zonesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Customer_vacancy_jobs_aggregate($limit: Int, $orderBy: [typeGQLcustomer_vacancy_jobs_order_by!], $offset: Int, $where: typeGQLcustomer_vacancy_jobs_bool_exp, $customerVacancyJobsAggregateWhere2: typeGQLcustomer_vacancy_jobs_bool_exp) {\n  customer_vacancy_jobs_aggregate(where: $customerVacancyJobsAggregateWhere2) {\n    aggregate {\n      count\n    }\n  }\n  customer_vacancy_jobs(limit: $limit, order_by: $orderBy, offset: $offset, where: $where) {\n    id\n    title\n    state\n    city\n    status\n    types_hiring {\n      title\n    }\n    types_working {\n      title\n    }\n    salary_min\n    salary_max\n    date_published\n  }\n}\n\n"): (typeof documents)["\n  query Customer_vacancy_jobs_aggregate($limit: Int, $orderBy: [typeGQLcustomer_vacancy_jobs_order_by!], $offset: Int, $where: typeGQLcustomer_vacancy_jobs_bool_exp, $customerVacancyJobsAggregateWhere2: typeGQLcustomer_vacancy_jobs_bool_exp) {\n  customer_vacancy_jobs_aggregate(where: $customerVacancyJobsAggregateWhere2) {\n    aggregate {\n      count\n    }\n  }\n  customer_vacancy_jobs(limit: $limit, order_by: $orderBy, offset: $offset, where: $where) {\n    id\n    title\n    state\n    city\n    status\n    types_hiring {\n      title\n    }\n    types_working {\n      title\n    }\n    salary_min\n    salary_max\n    date_published\n  }\n}\n\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Customer_zones($where: typeGQLcustomer_zones_bool_exp) {\n    customer_zones(where: $where) {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  query Customer_zones($where: typeGQLcustomer_zones_bool_exp) {\n    customer_zones(where: $where) {\n      id\n      title\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;