import { Button, Grid, Link } from "@mui/material";
import styles from "./JobCard.module.css";

function JobCard({
  id,
  role,
  publishedAt,
  city,
  state,
  format,
  type,
  salaryMin,
  salaryMax,
}) {
  return (
    <Grid container item className={styles.jobCardContainer} p={2} rowGap={5}>
      {/* header card content*/}
      <Grid container item rowGap={3}>
        <Grid container item rowGap={1}>
          <Grid
            container
            item
            sx={{
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                lg: "row",
              },
              justifyContent: { lg: "space-between" },
              alignItems: { xs: "flex-start", sm: "flex-start", lg: "center" },
              rowGap: { xs: "8px", sm: "8px" },
            }}
          >
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <h6 className="disableMarginBlock">
                {id} • {role}
              </h6>
            </Grid>
            <Grid item sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <h5 className="disableMarginBlock">
                {id} • {role}
              </h5>
            </Grid>

            <Grid item>
              <span
                className={`xs_body disableMarginBlock ${styles.publishedDate}`}
              >
                Publicada em {publishedAt}
              </span>
            </Grid>
          </Grid>
          <Grid item>
            <span
              className={`disableMarginBlock xs_body ${styles.jobLocation}`}
            >
              {city}, {state}
            </span>
          </Grid>
        </Grid>

        <Grid container item columnGap={2} rowGap={2}>
          {/* badges section*/}
          <Grid item className={`${styles.badgeJobCard} xs_body`}>
            {format}
          </Grid>
          <Grid item className={`${styles.badgeJobCard} xs_body`}>
            {type}
          </Grid>
          <Grid item className={`${styles.badgeJobCard} xs_body`}>
            {salaryMin} - {salaryMax}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={12} lg={12}>
        <Link
          href={`${process.env.REACT_APP_CANDIDATE_URL}/vacancy/${id}`}
          underline="none"
          sx={{display:'flex', flex: 1}}
        >
          <Button variant="outlined" color="primary" fullWidth={true}>
            Quero essa vaga
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default JobCard;
