import styles from "./CarrouselEnterprise.module.css";
import Marquee from "react-fast-marquee";
import { Grid } from "@mui/material";

import Deloitte from "../../imgs/enterprises/deloitte@2x.png";
import GrantThornton from "../../imgs/enterprises/grantThornton@2x.png";
import Direto from "../../imgs/enterprises/direto@2x.png";
import Kapcon from "../../imgs/enterprises/kapcon@2x.png";
import ViaInvest from "../../imgs/enterprises/viaInvest@2x.png";
import Abordin from "../../imgs/enterprises/abordin@2x.png";
import Agrelli from "../../imgs/enterprises/agrelli@2x.png";
import Contplan from "../../imgs/enterprises/contplan@2x.png";
import Jettax from "../../imgs/enterprises/jettax@2x.png";
import Ntw from "../../imgs/enterprises/ntw@2x.png";
import Omie from "../../imgs/enterprises/omie@2x.png";
import Propec from "../../imgs/enterprises/propec@2x.png";
import Syhus from "../../imgs/enterprises/syhus@2x.png";

function CarrouselEnterprises() {
  return (
    <Grid container direction="row" justifyContent="center" py={10}>
      <Grid item justifyContent={'center'} md={12} lg={12} sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
        <h5 className={styles.carrouselTitle}>
          Empresas que contratam na Connectabil
        </h5>
      </Grid>
      <Grid item xs={12} sm={12} justifyContent={'center'} sx={{display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
        <h3 className={styles.carrouselTitle}>
          Empresas que contratam na Connectabil
        </h3>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} mt={5}>
        <Marquee gradient={true}  gradientWidth={80}>
          <div className={styles.containerEnterprises}>
            <img
              src={Deloitte}
              alt="Deloitte"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={GrantThornton}
              alt="Grant Thornton"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Direto}
              alt="Direto"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Kapcon}
              alt="Kapcon"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={ViaInvest}
              alt="Via Invest"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Abordin}
              alt="Abordin"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Agrelli}
              alt="Agrelli"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Contplan}
              alt="Contplan"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Jettax}
              alt="Jettax"
              className={styles.imgEnterprise}
            ></img>
            <img
              src={Ntw}
              alt="NTW Contabilidade"
              className={styles.imgEnterprise}
            ></img>
            <img src={Omie} alt="Omie" className={styles.imgEnterprise}></img>
            <img
              src={Propec}
              alt="Propec"
              className={styles.imgEnterprise}
            ></img>
            <img src={Syhus} alt="Syhus" className={styles.imgEnterprise}></img>
          </div>
        </Marquee>
      </Grid>
    </Grid>
  );
}

export default CarrouselEnterprises;
