import { gql } from "../__generated__";

export const GQL_ZONE = gql(`
  query Customer_zones($where: typeGQLcustomer_zones_bool_exp) {
    customer_zones(where: $where) {
      id
      title
    }
  }
`);
