import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoSymbol } from "../imgs/Logo_OnlySymbol.svg";
import { ReactComponent as Logo } from "../imgs/logo_connectabil_horizontal.svg";
import styles from "./Navbar.module.css";

import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
} from "@mui/material";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const [openEnterprise, setOpenEnterprise] = useState(false);
  const animationOpenFade = {
    animation: styles.openFade + " 400ms ease-in-out forwards",
  };
  const animationCloseFade = {
    animation: styles.closeFade + " 400ms ease-in-out forwards",
  };

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  function toogleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  function openEnterpriseMobile() {
    setOpenEnterprise(true);
    setMounted(!isMounted);
  }

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#FFFFFF" }}>
      <Container>
        <Toolbar disableGutters>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              {/* displays only on md and above */}
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Logo className={styles.logo} />
              </Box>
              {/* displays only on sm and below */}
              <Box sx={{ display: { xs: "flex", md: "none" }, height: "24px" }}>
                <NavLink to="/">
                  <LogoSymbol className={styles.logo_onlySymbol} />
                </NavLink>
              </Box>
            </Grid>

            <Grid item>
              {/* displays only on md and above */}
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <ul className={styles.items_list}>
                  <NavLink to="/">
                    <li>Início</li>
                  </NavLink>
                  <NavLink to="/talentos">
                    <li>Talentos</li>
                  </NavLink>
                  <NavLink to="/vagas">
                    <li>Vagas</li>
                  </NavLink>
                  <li>
                    <span
                      id="enterprise-btn"
                      aria-controls={open ? "enterprise-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className={open ? styles.menuOpened : ""}
                    >
                      Empresa
                      {open ? (
                        <ArrowDropDownRoundedIcon
                          className={styles.dropdownClicked}
                        />
                      ) : (
                        <ArrowDropDownRoundedIcon />
                      )}
                    </span>

                    <Menu
                      id="enterprise-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{ "aria-labelledby": "enterprise-btn" }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={styles.dropdownMenu}
                    >
                      <NavLink to="/empresas" onClick={handleClose}>
                        <MenuItem>Como Funciona</MenuItem>
                      </NavLink>

                      <Divider />
                      <MenuList>
                        <ListSubheader>Produtos</ListSubheader>
                        <NavLink to="/banco_talentos" onClick={handleClose}>
                          <MenuItem>Banco de Talentos</MenuItem>
                        </NavLink>
                        <NavLink
                          to="/gerenciador_talentos"
                          onClick={handleClose}
                        >
                          <MenuItem>Gerenciador de Talentos</MenuItem>
                        </NavLink>
                        <NavLink to="/assessoria" onClick={handleClose}>
                          <MenuItem>Assessoria</MenuItem>
                        </NavLink>
                      </MenuList>
                    </Menu>
                  </li>
                  <NavLink to="/comunidade">
                    <li>Comunidade</li>
                  </NavLink>
                  <a
                    href="https://blog.connectabil.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>Blog</li>
                  </a>
                  <NavLink to="/triagem">
                    <Button variant="contained" size="medium" color="primary">
                      Entrar / Cadastre-se
                    </Button>
                  </NavLink>
                </ul>
              </Box>

              {/* displays only on sm and below */}
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ borderRadius: "4px" }}
                  onClick={toogleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Grid>

            <Drawer
              anchor="top"
              open={openDrawer}
              onClose={toogleDrawer}
              sx={{ "& .MuiDrawer-paper": { height: "100%" } }}
            >
              <Grid container px={3} rowGap={5} paddingBottom={5}>
                <Grid
                  container
                  item
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ height: "56px" }}
                >
                  <Grid item>
                    <NavLink to="/">
                      <LogoSymbol className={styles.logo_onlySymbol} />
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={toogleDrawer}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container item justifyContent={"center"} xs={12} sn={12}>
                  <ul className={styles.items_list_mobile}>
                    <NavLink to="/" onClick={toogleDrawer}>
                      <li>Início</li>
                    </NavLink>
                    <NavLink to="/talentos" onClick={toogleDrawer}>
                      <li>Talentos</li>
                    </NavLink>
                    <NavLink to="/vagas" onClick={toogleDrawer}>
                      <li>Vagas</li>
                    </NavLink>
                    <li className={isMounted && styles.enterpriseOpenedMobile}>
                      <div
                        className={
                          styles.btnEnterprise +
                          " " +
                          (openEnterprise && styles.btnEnterpriseOpened)
                        }
                        onClick={openEnterpriseMobile}
                      >
                        <span>
                          Empresa
                          {isMounted ? (
                            <ArrowDropDownRoundedIcon
                              className={styles.dropdownClicked}
                            />
                          ) : (
                            <ArrowDropDownRoundedIcon />
                          )}
                        </span>
                      </div>

                      {openEnterprise && (
                        <Grid container item>
                          <ul
                            className={styles.enterpriseItems}
                            style={
                              isMounted ? animationOpenFade : animationCloseFade
                            }
                            onAnimationEnd={() => {
                              if (!isMounted) {
                                setOpenEnterprise(false);
                              }
                            }}
                          >
                            <NavLink to="/empresas" onClick={toogleDrawer}>
                              <li>Como Funciona</li>
                            </NavLink>
                            <Divider sx={{ borderColor: "#CCCCCC" }} />
                            <span className={styles.headerSectionProdutcs}>
                              Produtos
                            </span>
                            <NavLink to="banco_talentos" onClick={toogleDrawer}>
                              <li>Banco de Talentos</li>
                            </NavLink>
                            <NavLink
                              to="/gerenciador_talentos"
                              onClick={toogleDrawer}
                            >
                              <li>Gerenciador de Talentos</li>
                            </NavLink>
                            <NavLink to="/assessoria" onClick={toogleDrawer}>
                              <li>Assessoria</li>
                            </NavLink>
                          </ul>
                        </Grid>
                      )}
                    </li>
                    <NavLink to="/comunidade" onClick={toogleDrawer}>
                      <li>Comunidade</li>
                    </NavLink>
                    <a
                      href="https://blog.connectabil.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>Blog</li>
                    </a>

                    <NavLink to={"https://candidate.connectabil.com/auth2"}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        fullWidth={true}
                      >
                        Entrar / Cadastre-se
                      </Button>
                    </NavLink>
                  </ul>
                </Grid>
              </Grid>
            </Drawer>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
