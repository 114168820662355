import { useQuery } from "@apollo/client";
import { CloseRounded, Search } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Drawer,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  order_by,
  typeGQLcustomer_vacancy_jobs_bool_exp,
} from "../__generated__/graphql";
import { formatCurrency } from "../common/utils/formatCurrency";
import { formatDateInBR } from "../common/utils/formatDateInBR";
import { FormInputText } from "../components/Forms/InputTextField";
import JobCard from "../components/layout/JobCard";
import { GET_SUMMARY_AND_JOBS } from "../graphql/jobs.graphql";
import { GQL_ZONE } from "../graphql/zones.graphql";
import imgBgJobs from "../imgs/Home_BGJobs@2x.png";
import styles from "./Jobs.module.css";

function Jobs() {
  const gridRef = useRef<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [where, setWhere] = useState<typeGQLcustomer_vacancy_jobs_bool_exp>({});
  const methodsForm = useForm({
    mode: "onChange",
  });
  const position = methodsForm.watch("position");
  const salaryMin = methodsForm.watch("salary_min");
  const salaryMax = methodsForm.watch("salary_max");
  const formatWorking = methodsForm.watch("format_working");
  const formatHiring = methodsForm.watch("type_hiring");
  const location = methodsForm.watch("location");
  const code = methodsForm.watch("id");

  const { data } = useQuery(GET_SUMMARY_AND_JOBS, {
    variables: {
      orderBy: [
        {
          date_published: order_by.desc,
        },
      ],
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      where,
      customerVacancyJobsAggregateWhere2: where,
    },
  });

  const { data: zonesData } = useQuery(GQL_ZONE, {
    variables: {
      where: {
        id: {
          _nin: [4],
        },
      },
    },
  });
  const zones = zonesData?.customer_zones;

  const jobs = data?.customer_vacancy_jobs;
  const summary = data?.customer_vacancy_jobs_aggregate.aggregate;
  const totalPage = Math.ceil((summary?.count ?? 1) / rowsPerPage);

  function toogleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  function handleClose() {
    toogleDrawer();
    setOpenDrawer(false);
  }

  function handleChipClick(field: string, value: string) {
    methodsForm.setValue(field, value);
  }

  function handleCleanFilters() {
    methodsForm.reset({});
    methodsForm.setValue("location", "");
    methodsForm.setValue("position", "");
    methodsForm.setValue("salary_min", "");
    methodsForm.setValue("salary_max", "");
    methodsForm.setValue("id", "");
    setWhere({});
  }

  function handleSelectZone(e: SelectChangeEvent<string>) {
    console.log("e.target.value", e.target.value);
    if (isEmpty(e.target.value)) {
      setWhere((prev) => ({
        ...prev,
        zone_id: undefined,
      }));
      return;
    }

    setWhere((prev) => ({
      ...prev,
      zone_id: {
        _eq: Number(e.target.value),
      },
    }));
  }

  function handleChangePageAndScrollTop(newPage: number) {
    setPage(newPage);

    gridRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setWhere({
        id: code ? { _eq: code } : undefined,
        position:
          position && position?.length >= 4
            ? {
                title: {
                  _ilike: `%${position}%`,
                },
              }
            : undefined,
        salary_min: salaryMin ? { _gte: salaryMin * 100 } : undefined,
        salary_max: salaryMax ? { _lte: salaryMax * 100 } : undefined,
        types_working: formatWorking
          ? { title: { _eq: formatWorking } }
          : undefined,
        types_hiring: formatHiring
          ? { title: { _eq: formatHiring } }
          : undefined,
        _or: location
          ? [
              { state: { _ilike: `%${location}%` } },
              { city: { _ilike: `%${location}%` } },
            ]
          : undefined,
      });
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [
    position,
    salaryMin,
    salaryMax,
    formatWorking,
    formatHiring,
    location,
    code,
  ]);

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#FAFAFA",
        backgroundImage: `url(${imgBgJobs})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        zIndex: "1",
      }}
    >
      <Container>
        <Grid container paddingTop={20} paddingBottom={10}>
          <Grid container item rowGap={6} ref={gridRef}>
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <h4 className="disableMarginBlock">
                {summary?.count ?? 0} oportunidades te esperando
              </h4>
            </Grid>

            <Grid item sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              <h2 className="disableMarginBlock">
                {summary?.count ?? 0} Oportunidades te esperando
              </h2>
            </Grid>

            <Grid container item>
              <Grid container item lg={7} rowGap={3}>
                <Grid container item rowGap={3}>
                  <Grid
                    item
                    sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    xs={12}
                    sm={12}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                      startIcon={<FilterListRoundedIcon />}
                      onClick={toogleDrawer}
                    >
                      Filtros
                    </Button>
                  </Grid>
                  {jobs?.map((job) => {
                    return (
                      <Box key={job.id}>
                        <JobCard
                          id={job.id}
                          role={job.title}
                          publishedAt={formatDateInBR(job.date_published)}
                          city={job.city}
                          state={job.state}
                          format={job.types_hiring?.title}
                          type={job.types_working?.title}
                          salaryMin={formatCurrency(job.salary_min)}
                          salaryMax={formatCurrency(job.salary_max)}
                        />
                      </Box>
                    );
                  })}
                </Grid>

                <Grid container item justifyContent={"center"}>
                  <Pagination
                    color="primary"
                    count={totalPage}
                    shape="rounded"
                    page={page}
                    onChange={(e, value) => {
                      handleChangePageAndScrollTop(value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item lg={1}></Grid>

              <Grid
                container
                item
                lg={4}
                className={styles.containerFilters}
                alignSelf={"flex-start"}
                rowGap={3}
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              >
                <Grid
                  container
                  item
                  justifyContent={"space-between"}
                  paddingTop={3}
                  px={3}
                >
                  <Grid item>
                    <h6>Filtros</h6>
                  </Grid>
                  <Grid
                    container
                    item
                    xs
                    sm
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={handleCleanFilters}
                      >
                        Limpar Filtros
                      </Button>
                    </Grid>
                    <Grid
                      item
                      display={{ xs: "none", sm: "block", md: "none" }}
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={handleClose}
                      >
                        <CloseRounded />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12}>
                  <Divider />
                </Grid>

                <FormProvider {...methodsForm}>
                  <Grid container item px={3} rowGap={4} paddingBottom={3}>
                    <Grid container item rowGap={1}>
                      <Grid container item>
                        <span className={styles.spanTitle}>Cargos</span>
                      </Grid>
                      <Grid container item>
                        <FormInputText
                          variant="outlined"
                          placeholder="Quais cargos você busca?"
                          label="Cargos"
                          autoComplete=""
                          fullWidth
                          name="position"
                          control={methodsForm.control}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item rowGap={1}>
                      <Grid item>
                        <span className={styles.spanTitle}>Código da Vaga</span>
                      </Grid>

                      <Grid container item>
                        <FormInputText
                          type={"search"}
                          fullWidth={true}
                          variant="outlined"
                          placeholder="Código da Vaga"
                          autoComplete=""
                          label="Código da Vaga"
                          name="id"
                          control={methodsForm.control}
                        />
                      </Grid>
                    </Grid>

                    {/* Salary */}
                    <Grid container item rowGap={1}>
                      <Grid item>
                        <span className={styles.spanTitle}>Salário</span>
                      </Grid>
                      <Grid container item columnGap={2}>
                        <Grid item lg>
                          <FormInputText
                            variant="outlined"
                            autoComplete=""
                            fullWidth
                            name="salary_min"
                            control={methodsForm.control}
                            label="Valor Mínimo"
                          />
                        </Grid>
                        <Grid item lg>
                          <FormInputText
                            variant="outlined"
                            autoComplete=""
                            fullWidth
                            name="salary_max"
                            control={methodsForm.control}
                            label="Valor Maximo"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Format */}
                    <Grid container item rowGap={1}>
                      <Grid item>
                        <span className={styles.spanTitle}>
                          Formato de Trabalho
                        </span>
                      </Grid>
                      <Grid container item columnGap={2}>
                        <Chip
                          label={"Híbrido"}
                          variant={
                            where?.types_working?.title?._eq === "Hibrido"
                              ? "filled"
                              : "outlined"
                          }
                          clickable
                          color={
                            where?.types_working?.title?._eq === "Hibrido"
                              ? "success"
                              : "default"
                          }
                          onClick={() =>
                            handleChipClick("format_working", "Hibrido")
                          }
                        />
                        <Chip
                          label={"Presencial"}
                          variant={
                            where?.types_working?.title?._eq === "Presencial"
                              ? "filled"
                              : "outlined"
                          }
                          clickable
                          color={
                            where?.types_working?.title?._eq === "Presencial"
                              ? "success"
                              : "default"
                          }
                          onClick={() =>
                            handleChipClick("format_working", "Presencial")
                          }
                        />
                        <Chip
                          label={"Remoto"}
                          variant={
                            where?.types_working?.title?._eq === "Remoto"
                              ? "filled"
                              : "outlined"
                          }
                          clickable
                          color={
                            where?.types_working?.title?._eq === "Remoto"
                              ? "success"
                              : "default"
                          }
                          onClick={() =>
                            handleChipClick("format_working", "Remoto")
                          }
                        />
                      </Grid>
                    </Grid>

                    {/* Types */}
                    <Grid container item rowGap={1}>
                      <Grid item>
                        <span className={styles.spanTitle}>
                          Tipo de Contratação
                        </span>
                      </Grid>
                      <Grid container item columnGap={2}>
                        <Chip
                          label={"Estagiário"}
                          variant={
                            where?.types_hiring?.title?._eq === "Estagiário"
                              ? "filled"
                              : "outlined"
                          }
                          color={
                            where?.types_hiring?.title?._eq === "Estagiário"
                              ? "success"
                              : "default"
                          }
                          clickable
                          onClick={() =>
                            handleChipClick("type_hiring", "Estagiário")
                          }
                        />
                        <Chip
                          label={"CLT"}
                          variant={
                            where?.types_hiring?.title?._eq === "CLT"
                              ? "filled"
                              : "outlined"
                          }
                          color={
                            where?.types_hiring?.title?._eq === "CLT"
                              ? "success"
                              : "default"
                          }
                          clickable
                          onClick={() => handleChipClick("type_hiring", "CLT")}
                        />
                        <Chip
                          label={"PJ"}
                          variant={
                            where?.types_hiring?.title?._eq === "PJ"
                              ? "filled"
                              : "outlined"
                          }
                          color={
                            where?.types_hiring?.title?._eq === "PJ"
                              ? "success"
                              : "default"
                          }
                          onClick={() => handleChipClick("type_hiring", "PJ")}
                        />
                        <Chip
                          label={"Temporário"}
                          color={
                            where?.types_hiring?.title?._eq === "Temporario"
                              ? "success"
                              : "default"
                          }
                          variant={
                            where?.types_hiring?.title?._eq === "Temporario"
                              ? "filled"
                              : "outlined"
                          }
                          clickable
                          onClick={() =>
                            handleChipClick("type_hiring", "Temporario")
                          }
                        />
                      </Grid>
                    </Grid>

                    {/* Location */}
                    <Grid container item rowGap={1}>
                      <Grid item>
                        <span className={styles.spanTitle}>Localidade</span>
                      </Grid>

                      <Grid container item rowGap={2}>
                        <Grid container item>
                          <FormInputText
                            type={"search"}
                            fullWidth={true}
                            variant="outlined"
                            placeholder="Cidade, Estado"
                            autoComplete=""
                            label="Cidade, Estado"
                            name="location"
                            control={methodsForm.control}
                          />
                        </Grid>
                        <Grid container item>
                          <Select
                            variant="outlined"
                            labelId="zoneLabel"
                            fullWidth={true}
                            displayEmpty={true}
                            onChange={handleSelectZone}
                            placeholder="Pesquisar por Zona"
                            value={String(where?.zone_id?._eq ?? 0) ?? ""}
                          >
                            <MenuItem value="0" disabled>
                              Pesquisar por Zona
                            </MenuItem>
                            {zones?.map((zone) => (
                              <MenuItem value={String(zone.id)} key={zone.id}>
                                {zone?.title}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            Somente para a Região de São Paulo Capital
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormProvider>
              </Grid>

              <Drawer
                anchor="bottom"
                open={openDrawer}
                sx={{ "& .MuiDrawer-paper": { height: "100%" } }}
              >
                <Grid container>
                  <Grid
                    container
                    item
                    py={3}
                    px={3}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid
                      container
                      item
                      justifyContent={"space-between"}
                      paddingTop={3}
                      px={3}
                    >
                      <Grid item>
                        <h6>Filtros</h6>
                      </Grid>
                      <Grid
                        container
                        item
                        xs
                        sm
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={handleCleanFilters}
                          >
                            Limpar Filtros
                          </Button>
                        </Grid>
                        <Grid
                          item
                          display={{ xs: "none", sm: "block", md: "none" }}
                        >
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={handleClose}
                          >
                            <CloseRounded />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12}>
                      <Divider />
                    </Grid>

                    <FormProvider {...methodsForm}>
                      <Grid container item px={3} rowGap={4} paddingBottom={3}>
                        <Grid container item rowGap={1}>
                          <Grid container item>
                            <span className={styles.spanTitle}>Cargos</span>
                          </Grid>
                          <Grid container item>
                            <FormInputText
                              variant="outlined"
                              placeholder="Quais cargos você busca?"
                              label="Cargos"
                              autoComplete=""
                              fullWidth
                              name="position"
                              control={methodsForm.control}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item rowGap={1}>
                          <Grid item>
                            <span className={styles.spanTitle}>
                              Código da Vaga
                            </span>
                          </Grid>

                          <Grid container item>
                            <FormInputText
                              type={"search"}
                              fullWidth={true}
                              variant="outlined"
                              placeholder="Código da Vaga"
                              autoComplete=""
                              label="Código da Vaga"
                              name="id"
                              control={methodsForm.control}
                            />
                          </Grid>
                        </Grid>

                        {/* Salary */}
                        <Grid container item rowGap={1}>
                          <Grid item>
                            <span className={styles.spanTitle}>Salário</span>
                          </Grid>

                          <Grid container item columnGap={2}>
                            <Grid item flex={1}>
                              <FormInputText
                                variant="outlined"
                                autoComplete=""
                                fullWidth
                                name="salary_min"
                                control={methodsForm.control}
                                label="Valor Mínimo"
                              />
                            </Grid>
                            <Grid item flex={1}>
                              <FormInputText
                                variant="outlined"
                                autoComplete=""
                                fullWidth
                                name="salary_max"
                                control={methodsForm.control}
                                label="Valor Maximo"
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Format */}
                        <Grid container item rowGap={1}>
                          <Grid item>
                            <span className={styles.spanTitle}>
                              Formato de Trabalho
                            </span>
                          </Grid>
                          <Grid container item columnGap={2}>
                            <Chip
                              label={"Híbrido"}
                              variant={
                                where?.types_working?.title?._eq === "Hibrido"
                                  ? "filled"
                                  : "outlined"
                              }
                              clickable
                              color={
                                where?.types_working?.title?._eq === "Hibrido"
                                  ? "success"
                                  : "default"
                              }
                              onClick={() =>
                                handleChipClick("format_working", "Hibrido")
                              }
                            />
                            <Chip
                              label={"Presencial"}
                              variant={
                                where?.types_working?.title?._eq ===
                                "Presencial"
                                  ? "filled"
                                  : "outlined"
                              }
                              clickable
                              color={
                                where?.types_working?.title?._eq ===
                                "Presencial"
                                  ? "success"
                                  : "default"
                              }
                              onClick={() =>
                                handleChipClick("format_working", "Presencial")
                              }
                            />
                            <Chip
                              label={"Remoto"}
                              variant={
                                where?.types_working?.title?._eq === "Remoto"
                                  ? "filled"
                                  : "outlined"
                              }
                              clickable
                              color={
                                where?.types_working?.title?._eq === "Remoto"
                                  ? "success"
                                  : "default"
                              }
                              onClick={() =>
                                handleChipClick("format_working", "Remoto")
                              }
                            />
                          </Grid>
                        </Grid>

                        {/* Types */}
                        <Grid container item rowGap={1}>
                          <Grid item>
                            <span className={styles.spanTitle}>
                              Tipo de Contratação
                            </span>
                          </Grid>
                          <Grid container item columnGap={2}>
                            <Chip
                              label={"Estagiário"}
                              variant={
                                where?.types_hiring?.title?._eq === "Estagiário"
                                  ? "filled"
                                  : "outlined"
                              }
                              color={
                                where?.types_hiring?.title?._eq === "Estagiário"
                                  ? "success"
                                  : "default"
                              }
                              clickable
                              onClick={() =>
                                handleChipClick("type_hiring", "Estagiário")
                              }
                            />
                            <Chip
                              label={"CLT"}
                              variant={
                                where?.types_hiring?.title?._eq === "CLT"
                                  ? "filled"
                                  : "outlined"
                              }
                              color={
                                where?.types_hiring?.title?._eq === "CLT"
                                  ? "success"
                                  : "default"
                              }
                              clickable
                              onClick={() =>
                                handleChipClick("type_hiring", "CLT")
                              }
                            />
                            <Chip
                              label={"PJ"}
                              variant={
                                where?.types_hiring?.title?._eq === "PJ"
                                  ? "filled"
                                  : "outlined"
                              }
                              color={
                                where?.types_hiring?.title?._eq === "PJ"
                                  ? "success"
                                  : "default"
                              }
                              onClick={() =>
                                handleChipClick("type_hiring", "PJ")
                              }
                            />
                            <Chip
                              label={"Temporário"}
                              color={
                                where?.types_hiring?.title?._eq === "Temporario"
                                  ? "success"
                                  : "default"
                              }
                              variant={
                                where?.types_hiring?.title?._eq === "Temporario"
                                  ? "filled"
                                  : "outlined"
                              }
                              clickable
                              onClick={() =>
                                handleChipClick("type_hiring", "Temporario")
                              }
                            />
                          </Grid>
                        </Grid>

                        {/* Location */}
                        <Grid container item rowGap={1}>
                          <Grid item>
                            <span className={styles.spanTitle}>Localidade</span>
                          </Grid>

                          <Grid container item rowGap={2}>
                            <Grid container item>
                              <FormInputText
                                type={"search"}
                                fullWidth={true}
                                variant="outlined"
                                placeholder="Cidade, Estado"
                                autoComplete=""
                                label="Cidade, Estado"
                                name="location"
                                control={methodsForm.control}
                              />
                            </Grid>
                            <Grid container item>
                              <Select
                                variant="outlined"
                                labelId="zoneLabel"
                                fullWidth={true}
                                displayEmpty={true}
                                onChange={handleSelectZone}
                                placeholder="Pesquisar por Zona"
                                value={String(where?.zone_id?._eq ?? 0) ?? ""}
                              >
                                <MenuItem value="0" disabled>
                                  Pesquisar por Zona
                                </MenuItem>
                                {zones?.map((zone) => (
                                  <MenuItem
                                    value={String(zone.id)}
                                    key={zone.id}
                                  >
                                    {zone?.title}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                Somente para a Região de São Paulo Capital
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          startIcon={<Search />}
                          onClick={handleClose}
                        >
                          Pesquisar
                        </Button>
                      </Grid>
                    </FormProvider>
                  </Grid>
                </Grid>
              </Drawer>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default Jobs;
