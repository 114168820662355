const connectabilColors = {
    green:{
        100: '#D1FADF',
        200: '#52CC7B',
        300: '#22D960',
        400: '#1EBD54',
        500: '#189944',
        600: '#0D8034',
        700: '#0A6629',
        800: '#000000',
        900: '#000000',
    },

    purple:{
        100: '#F9E7FE',
        200: '#E4AAF2',
        300: '#C685CC',
        400: '#893F99',
        500: '#7D188E',
        600: '#54085B',
        700: '#46024D',
        800: '#250133',
        900: '#000000',
    },

    gray:{
        100: '#FAFAFA',
        200: '#F2F2F2',
        300: '#E6E6E6',
        400: '#CCCCCC',
        500: '#BFBFBF',
        600: '#999999',
        700: '#666666',
        800: '#333333',
        900: '0D0D0D', 
    }
}

export default connectabilColors