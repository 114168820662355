import { gql } from "../__generated__";

export const GET_SUMMARY_AND_JOBS = gql(`
  query Customer_vacancy_jobs_aggregate($limit: Int, $orderBy: [typeGQLcustomer_vacancy_jobs_order_by!], $offset: Int, $where: typeGQLcustomer_vacancy_jobs_bool_exp, $customerVacancyJobsAggregateWhere2: typeGQLcustomer_vacancy_jobs_bool_exp) {
  customer_vacancy_jobs_aggregate(where: $customerVacancyJobsAggregateWhere2) {
    aggregate {
      count
    }
  }
  customer_vacancy_jobs(limit: $limit, order_by: $orderBy, offset: $offset, where: $where) {
    id
    title
    state
    city
    status
    types_hiring {
      title
    }
    types_working {
      title
    }
    salary_min
    salary_max
    date_published
  }
}

`);
