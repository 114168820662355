export const Community = () => {
  //iframe https://connectabil.curseduca.pro/m/courses
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src="https://connectabil.curseduca.pro/m/courses"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
