import { createTheme } from "@mui/material/styles";
import colors from "./ConnectabilColors";

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: colors.green[400],
    },
    secondary: {
      main: colors.purple[600],
    },
    whitePurple: {
      main: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          boxShadow: "none",
          textTransform: "none",
        },
        sizeSmall: {
          fontSize: "14px",
          lineHeight: "24px",
          padding: "6px 12px",
        },
        sizeMedium: {
          fontSize: "16px",
          lineHeight: "24px",
          padding: "12px 24px",
        },
        sizeLarge: {
          fontSize: "18px",
          lineHeight: "24px",
          padding: "16px 40px",
        },
        containedPrimary: {
          color: "#FFF",
          "&:hover": {
            backgroundColor: colors.green[300],
            boxShadow: "none",
          },
        },
        outlinedPrimary: {
          borderColor: "#CCC",
        },
        textPrimary: {
          color: colors.green[400],
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: colors.gray[200],
          },
        },
        containedSecondary: {
          color: "#FFF",
          "&:hover": {
            backgroundColor: colors.purple[500],
            boxShadow: "none",
          },
        },
        containedWhitePurple: {
          color: colors.purple[600],
          "&:hover": {
            backgroundColor: colors.purple[100],
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: colors.gray[200],
          },
        },
        sizeMedium: {
          padding: "12px 24px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          boxShadow: "0px 4px 8px 2px rgba(13, 13, 13, 0.25)",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
          margin: "16px 16px",
          borderRadius: "4px",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: "#0d0d0d",
          fontWeight: "600",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E6E6E6",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "solid",
          borderWidth: "1px 2px 1px 2px",
          borderColor: "#E6E6E6",
          overflow: "hidden",
          "&:first-of-type": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            border: "solid",
            borderWidth: "2px 2px 1px 2px",
            borderColor: "#E6E6E6",
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            border: "solid",
            borderWidth: "1px 2px 4px 2px",
            borderColor: "#E6E6E6",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "24px 32px",
          "&.Mui-expanded": {
            boxShadow: "inset 4px 0px #1EBD54;",
            backgroundColor: colors.gray[200],
            ...(ownerState.expandedColor === "primary" && {
              color: colors.green[500],
              boxShadow: "inset 4px 0px #1EBD54;",
            }),
            ...(ownerState.expandedColor === "secondary" && {
              color: colors.purple[600],
              boxShadow: "inset 4px 0px #54085B;",
            }),
          },
        }),
        content: {
          margin: "0px",
        },
        expandIconWrapper: ({ ownerState }) => ({
          color: colors.gray[800],
          "&.Mui-expanded": {
            ...(ownerState.expandedColor === "primary" && {
              color: colors.green[500],
            }),
            ...(ownerState.expandedColor === "secondary" && {
              color: colors.purple[600],
            }),
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "8px 32px 24px 32px",
          ...(ownerState.expandedColor === "primary" && {
            boxShadow: "inset 4px 0px #1EBD54;",
          }),
          ...(ownerState.expandedColor === "secondary" && {
            boxShadow: "inset 4px 0px #54085B;",
          }),
          backgroundColor: colors.gray[200],
        }),
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          backgroundColor: colors.gray[300],
          "&.Mui-selected": {
            color: "#FFFFFF",
          },
        },
        ellipsis: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

export default theme;
