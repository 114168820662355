import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { useState } from "react";

import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";

//image
import imgFaq from "../../imgs/FAQ@2x.png";

function Faq({ questionsAnswers, color, btnText, btnLink }) {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let emptyFaq = true;

  questionsAnswers != null ? (emptyFaq = false) : (emptyFaq = true);

  return (
    <Grid container justifyContent="center" py={10} rowGap={12}>
      <Grid container item lg={12}>
        <Grid container item columnSpacing={3} rowGap={7}>
          {/* Img */}
          <Grid
            item
            xs={12}
            sm={12}
            lg={5}
            sx={{
              aspectRatio: "1/1",
              backgroundImage: `url(${imgFaq})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundOrigin: "content-box",
            }}
          ></Grid>
          {/* Accorddion */}
          <Grid container item lg={7} rowGap={2} justifyContent={"center"}>
            <Grid container item rowGap={5}>
              <Grid
                item
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              >
                <h4 className="disableMarginBlock">
                  Ficou alguma dúvida? Esclarecemos outros pontos aqui pra você
                </h4>
              </Grid>

              <Grid
                item
                sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
              >
                <h3 className="disableMarginBlock">
                  Ficou alguma dúvida? Esclarecemos outros pontos aqui pra você
                </h3>
              </Grid>

              <Grid item>
                {!emptyFaq ? (
                  questionsAnswers.map((e, i) => {
                    return (
                      <Accordion
                        key={i}
                        id={i}
                        disableGutters={true}
                        expanded={expanded === "panel" + i}
                        onChange={handleChange("panel" + i)}
                      >
                        <AccordionSummary
                          expandedColor={color}
                          expandIcon={
                            <ArrowDropDownCircleRoundedIcon
                              expandedColor={color}
                            />
                          }
                        >
                          <h6>{e.question}</h6>
                        </AccordionSummary>

                        <AccordionDetails expandedColor={color}>
                          <p>{e.answer}</p>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <h5>Esse componente precisa de Perguntas e Respostas</h5>
                )}
              </Grid>
            </Grid>
            <Grid item sx={{ color: "#666666", textAlign: "center" }}>
              <span className="xs_body">
                Se ainda ficou com dúvidas chama a gente que vamos te ajudar.
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} lg={6}>
        <Link href={btnLink} underline="none">
          <Button variant="contained" color={color} fullWidth={true}>
            {btnText}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default Faq;
