import { Box, Button, Container, Fab, Grid, Link } from "@mui/material";
import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import Faq from "../components/layout/Faq";
import styles from "./TalentsManager.module.css";

//images
import imgPointerBanner from "../imgs/PointerRecruiterATS@2x.png";
import imgHero from "../imgs/RecruiterATS@2x.png";
import imgTalentProfile from "../imgs/TalentoPerfil@2x.png";
import imgSkillsTests from "../imgs/TestesTecnicos@2x.png";
import { WhatsApp } from "@mui/icons-material";

const faqQuestionAnswers = [
  {
    question: "Como divulgarei as minhas vagas?",
    answer:
      "Cada vaga tem o seu link para divulgá-la, e caso prefira pode compartilhar a página de carreiras da sua empresa, onde aparecem todas as vagas.",
  },
  {
    question: "Quantas vagas posso publicar?",
    answer:
      "O plano é ilimitado, pode publicar as vagas abertas no momento ou pode deixar as mais necessárias sempre publicadas, para banco de currículo.",
  },
  {
    question: "Para usar os testes tenho um custo extra?",
    answer:
      "Tanto os testes técnicos quanto o fit cultural já estão inclusos no plano. Os testes são das áreas: Contábil, fiscal, departamento pessoal e financeira.",
  },
  {
    question: "O candidato tem acesso ao status da vaga?",
    answer:
      "Pelo login do talento ele consegue saber o status de cada vaga. Quando a pessoa é aprovada ou desclassificada, ele também recebe a comunicação por email.",
  },
  {
    question: "Tenho acesso ao banco de talentos?",
    answer: "O Gerenciador de Talentos não oferece acesso ao banco de dados.  ",
  },
];

function TalentsManager() {
  return (
    <Container disableGutters={true} maxWidth={false}>
      <Fab
        variant="extended"
        color="primary"
        href="https://wa.me/+5511957055684"
        sx={{
          position: "fixed",
          bottom: { xs: "80px", sm: "80px", md: "56px" },
          right: { xs: "16px", sm: "16px", md: "136px" },
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
        }}
      >
        <WhatsApp sx={{ mr: 1 }} />
        Fale com um consultor
      </Fab>

      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{ position: "relative" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 40,
            right: 0,
            height: '536px',
            width: {xs: '180%', sm: '180%', md: '100%'},
            backgroundImage: `url(${imgHero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundOrigin: "content-box",
            backgroundPositionX : 'right',
            zIndex: 1,
          }}
        ></Box>
        <Container sx={{ position: "relative", zIndex: 4 }}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            paddingTop={{ xs: 50, sm: 50, md: 20 }}
            paddingBottom={{ xs: 5, sm: 5, md: 10 }}
            rowGap={7}
          >
            <Grid item>
              <h1 className="disableMarginBlock">
                Todo o seu processo seletivo em um único lugar
              </h1>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Link
                href={`${
                  process.env.REACT_APP_CUSTOMER_URL
                }/new-plan-validation?plan=${
                  process.env.PLAN_MANAGER_TALENT ?? 4
                }&type_plan=${process.env.PROD_MANAGER_TALENT ?? 4}`}
                underline="none"
              >
                <Button variant="contained" color="secondary" fullWidth={true}>
                  Escolher Produto
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container>
        <Grid container py={10} rowGap={7}>
          <Grid container item lg={7} rowGap={2}>
            <h2 className="disableMarginBlock">
              Gerencie os perfis dos talentos
            </h2>
            <p>
              Ninguém gosta de perder tempo com planilhas e passando
              dificuldades com organização de informações certo?
              <br />
              <br />O Gerenciador de Talentos vai te ajudar a organizar todo o
              seu processo seletivo, perfis dos talentos mais adequados,
              favoritar candidatos, respostas automáticas de email, entre
              outros.
            </p>
          </Grid>
          <Grid container item lg={12} rowGap={8}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                lg={5}
                sx={{
                  height: "280px",
                  backgroundImage: `url(${imgSkillsTests})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundOrigin: "content-box",
                }}
              ></Grid>
              <Grid item lg={1}></Grid>
              <Grid container item xs={12} sm={12} lg={6} rowGap={2}>
                <Grid item display={{ xs: "none", sm: "none", md: "flex" }}>
                  {/* desktop version */}
                  <h5 className="disableMarginBlock">
                    Aplique testes técnicos e fit cultural
                  </h5>
                </Grid>

                {/* mobile version */}
                <Grid item display={{ xs: "flex", sm: "flex", md: "none" }}>
                  <h3 className="disableMarginBlock">
                  Aplique testes técnicos e fit cultural
                  </h3>
                </Grid>
                <p className="disableMarginBlock">
                  Quanto menos tempo usar para o processo seletivo, melhor. Não
                  é mesmo?
                  <br />
                  <br />
                  Além de já ter acesso ao sistema todo configurado, você pode
                  aplicar nossos testes técnicos com correção automática e
                  analisar o fit cultural de cada candidato.
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              flexDirection={{ xs: "row", sm: "row", md: "row-reverse" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                lg={5}
                sx={{
                  height: "280px",
                  backgroundImage: `url(${imgTalentProfile})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundOrigin: "content-box",
                }}
              ></Grid>
              <Grid container item xs={12} sm={12} lg={6} rowGap={2}>
                {/* desktop version */}
                <Grid item display={{ xs: "none", sm: "none", md: "flex" }}>
                  <h5 className="disableMarginBlock">
                    Perfil completo dos talentos
                  </h5>
                </Grid>
                {/* mobile version */}
                <Grid item display={{ xs: "flex", sm: "flex", md: "none" }}>
                  <h3 className="disableMarginBlock">
                    Perfil completo dos talentos
                  </h3>
                </Grid>
                <p className="disableMarginBlock">
                  Nosso propósito é facilitar seu processo de contratação, sendo
                  assim, o perfil do talento que ficará em destaque será o mais
                  adequado para desempenhar a função dentro da sua empresa.
                </p>
              </Grid>

              <Grid
                item
                lg={1}
                display={{ xs: "none", sm: "none", md: "flex" }}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container py={10}>
          <Grid
            container
            item
            lg={12}
            py={7}
            px={{ xs: 3, sm: 3, md: 12 }}
            className={styles.container_banner}
            rowGap={{ xs: 0, sm: 0, md: 12 }}
            height={{ xs: "640px", sm: "640px", md: "auto" }}
          >
            <Grid item lg={12}>
              <Grid container item lg={8} rowGap={2}>
                <span className={styles.bannerBadgePrice}>
                  Por apenas R$79,90
                </span>
                <h2 className="disableMarginBlock">
                  Seu processo seletivo mais rápido e assertivo
                </h2>
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={12} lg={3} alignItems={"flex-end"}>
              <Link
                href={`${
                  process.env.REACT_APP_CUSTOMER_URL
                }/new-plan-validation?plan=${
                  process.env.PLAN_MANAGER_TALENT ?? 4
                }&type_plan=${process.env.PROD_MANAGER_TALENT ?? 4}`}
                underline="none"
              >
                <Button
                  variant="contained"
                  color="whitePurple"
                  fullWidth={true}
                >
                  Quero esse produto
                </Button>
              </Link>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              sx={{
                position: "absolute",
                right: { xs: "48px", sm: "48px", md: "96px" },
                top: { xs: "288px", sm: "288px", md: "0px" },
                height: "320px",
                backgroundImage: `url(${imgPointerBanner})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className={styles.pointerBanner}
            ></Grid>
            <Box className={styles.backgroundStripes}></Box>
          </Grid>
        </Grid>

        <Faq
          questionsAnswers={faqQuestionAnswers}
          color="secondary"
          btnText="Escolher Produto"
          btnLink={`${
            process.env.REACT_APP_CUSTOMER_URL
          }/new-plan-validation?plan=${
            process.env.PLAN_MANAGER_TALENT ?? 4
          }&type_plan=${process.env.PROD_MANAGER_TALENT ?? 4}`}
        />
        <CarrouselEnterprises />
      </Container>
    </Container>
  );
}

export default TalentsManager;
