export const formatCurrency = (valueInCent?: number | string | null) => {
  const parsedValue = Number(valueInCent);
  const valueIsNaN = isNaN(parsedValue);

  if (valueIsNaN) {
    return "R$ 0,00";
  }

  const valueInReal = parsedValue / 100;

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valueInReal);
};
