import { Box, Button, Container, Fab, Grid, Link } from "@mui/material";
import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import Faq from "../components/layout/Faq";
import styles from "./Assessoria.module.css";

import {
  BadgeRounded,
  DoneRounded,
  Groups3Rounded,
  SupervisedUserCircleRounded,
  WhatsApp,
} from "@mui/icons-material";
import imgHero from "../imgs/Assessoria_Cover@2x.png";

function Assessoria() {
  const listBenefitsLadder = [
    {
      title: "Nosso time de especialistas cuida da sua vaga!",
      desc: "Não tem RH Próprio ou o seu está cheio de demandas? Nós temos especialistas que vão te ajudar a encontrar o talento perfeito.",
    },
    {
      title: "Urgência para fechar um time? Nós resolvemos",
      desc: "Seu time tem pressa? Tem demandas paradas por não ter o profissional? Segura na nossa mão e entreviste os finalistas e feche o processo em 14 dias!",
    },
    {
      title: "Confie em quem sabe como ser assertivo",
      desc: "Assertividade conta muito em um processo seletivo, ainda mais quando se tem urgência. Nosso time sabe como te ajudar com isso.",
    },
  ];

  function mountCardBenefitLadder(i, title, desc) {
    let cardposition = "flex-start";
    if (i === 1) {
      cardposition = "center";
    }
    if (i === 2) {
      cardposition = "flex-end";
    }

    return (
      <Grid container item lg={12} justifyContent={cardposition}>
        <Grid container item lg={6} className={styles.cardBenefitsLadder} p={4}>
          <Grid item>
            <h4 className="disableMarginBlock">{title}</h4>
          </Grid>
          <Grid item>
            <p className="disableMarginBlock">{desc}</p>
          </Grid>
          <Box className={styles.backgroundStripes}></Box>
        </Grid>
      </Grid>
    );
  }

  const list4Reasons = [
    {
      icon: <DoneRounded />,
      title: "Publique uma vaga utilizando o produto Assessoria",
      desc: "Torne seus processos seletivos mais rápidos e assertivos. São poucos passos: subir sua vaga no portal, fazer o onboarding passando todos os detalhes da vaga para o recrutador e pronto!",
    },
    {
      icon: <SupervisedUserCircleRounded />,
      title: "Deixe que a nossa equipe cuide da sua vaga",
      desc: "Sabe a parte mais demorada do processo seletivo? Nós fazemos tudo isso pela sua empresa. Triamos os talentos, analisamos os testes e fit cultural e fazemos as entrevistas para você.",
    },
    {
      icon: <Groups3Rounded />,
      title: "Receba até 3 talentos alinhados com a sua vaga",
      desc: "É isso mesmo, fazemos toda a triagem e mandamos os 3 talentos finalistas com o parecer individual sobre eles. Entreviste os 3 e veja qual é o mais aderente para sua vaga. Invista tempo somente na parte final do processo.",
    },
    {
      icon: <BadgeRounded />,
      title: "Contrate o talento com maior afinidade com sua vaga",
      desc: "Contrate o talento baseado no currículo, nos testes técnicos, no fit cultural e no parecer do recrutador. Finalize o processo seletivo em 14 dias! E ainda tem a garantia de reposição.",
    },
  ];

  function mountCards4Reasons(icon, title, desc) {
    return (
      <Grid container item lg={5}>
        <Grid container item columnGap={3} alignItems={"center"}>
          <Grid item className={styles.badge4Reasons}>
            {icon}
          </Grid>
          <Grid item lg>
            <h5>{title}</h5>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item lg={2}></Grid>
          <Grid item lg>
            <p>{desc}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const questionsAnswers = [
    {
      question: "O pagamento é antecipado?",
      answer:
        "A forma de pagamento varia de acordo com o plano escolhido. A maioria não tem cobrança no momento de abertura.",
    },
    {
      question: "Não tenho RH interno, consigo abrir a vaga?",
      answer:
        "Esse plano é para quem não tem RH interno e não quer tirar uma pessoa da operação para realizar todo o processo. Com o nosso banco e conhecimento, entregamos a vaga em tempo recorde.",
    },
    {
      question: "Entrevistarei quantos candidatos?",
      answer:
        "Depende do plano escolhido, pode variar entre 2 a 6. Porém fazermos todo o processo de seleção aqui, para que consiga contratar com o mínimo de entrevista possível.",
    },
    {
      question: "Quanto tempo para receber os candidatos?",
      answer: "Depende do plano escolhido, varia entre 5 a 10 dias úteis.",
    },
    {
      question: "Tenho garantia de reposição?",
      answer:
        "Caso seja necessário a reposição de um talento, poderá ser solicitado uma reposição. O prazo varia de acordo com o plano escolhido.",
    },
  ];

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Fab
        variant="extended"
        color="primary"
        href="https://wa.me/+5511957055684"
        sx={{
          position: "fixed",
          bottom: { xs: "80px", sm: "80px", md: "56px" },
          right: { xs: "16px", sm: "16px", md: "136px" },
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
        }}
      >
        <WhatsApp sx={{ mr: 1 }} />
        Fale com um consultor
      </Fab>
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundImage: `url(${imgHero})`,
          backgroundSize: { xs: "200%", sm: "200%", md: "80%" },
          backgroundPosition: { xs: "top 56px right", md: "top 64px right" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Grid
            container
            xs={12}
            sm={12}
            lg={6}
            paddingBottom={10}
            paddingTop={{ xs: 40, sm: 40, md: 20 }}
            rowGap={7}
          >
            <Grid container item>
              <Grid
                item
                display={{ xs: "none", sm: "none", md: "flex" }}
                color={"#666"}
              >
                <h5 className={"disableMarginBlock"}>
                  Processo seletivo em apenas 14 dias!
                </h5>
              </Grid>
              <Grid
                item
                display={{ xs: "flex", sm: "flex", md: "none" }}
                color={"#666"}
              >
                <h4 className={"disableMarginBlock"}>
                  Processo seletivo em apenas 14 dias!
                </h4>
              </Grid>
              <Grid item>
                <h1 className={"disableMarginBlock"}>
                  Contratar nunca foi tão rápido
                </h1>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Link
                href={`${
                  process.env.REACT_APP_CUSTOMER_URL
                }/new-plan-validation?plan=${
                  process.env.PLAN_ASSESSORIA ?? 3
                }&type_plan=${process.env.PROD_ASSESSORIA ?? 1}`}
              >
                <Button variant="contained" color="secondary" fullWidth={true}>
                  Escolher Produto
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>

        <Container
          maxWidth={false}
          disableGutters={true}
          className={styles.benefitsLadder}
        >
          <Container>
            <Grid container py={10}>
              <Grid item>
                <h2>Vaga difícil? Nós damos o jeito!</h2>
              </Grid>
              <Grid container item rowGap={7}>
                {listBenefitsLadder.map((card, i) => {
                  return mountCardBenefitLadder(i, card.title, card.desc);
                })}
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Container>

      <Container>
        <Grid container gap={8} py={10}>
          {list4Reasons.map((e) => {
            return mountCards4Reasons(e.icon, e.title, e.desc);
          })}
        </Grid>

        <Faq
          questionsAnswers={questionsAnswers}
          color="secondary"
          btnText={"Escolher Produto"}
          btnLink={`${
            process.env.REACT_APP_CUSTOMER_URL
          }/new-plan-validation?plan=${
            process.env.PLAN_ASSESSORIA ?? 3
          }&type_plan=${process.env.PROD_ASSESSORIA ?? 1}`}
        />

        <CarrouselEnterprises />
      </Container>
    </Container>
  );
}

export default Assessoria;
