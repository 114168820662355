import styles from "./Footer.module.css";
import { ReactComponent as Logo } from "../imgs/logo_connectabil_horizontal.svg";
import { Divider, Grid, Button, IconButton, Container } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";

function Footer() {
  return (
    <footer>
      <Container maxWidth={false} disableGutters={true}>
        <Divider />
        <Container>
          <Grid container justifyContent="center" py={10}>
            <Grid container item rowSpacing={5}>
              {/* first's block start */}
              <Grid container item xs={12} sm={12} lg={12} columnGap={10}>
                <Grid container item columnSpacing={10} rowGap={10}>
                  {/* left column*/}
                  <Grid container item xs={12} sm={12} lg={4} rowSpacing={4}>
                    <Grid
                      item
                      sx={{ display: { xs: " none", sm: "none", md: "flex" } }}
                    >
                      <h6 className={styles.h6_footer}>
                        Entre em contato diretamente com nossos colaboradores
                      </h6>
                    </Grid>
                    <Grid
                      item
                      sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                    >
                      <h5 className={styles.h6_footer}>
                        Entre em contato diretamente com nossos colaboradores
                      </h5>
                    </Grid>

                    <Grid container item rowSpacing={3}>
                      <Grid item>
                        <a
                          href="mailto:contato@connectabil.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            startIcon={<EmailRoundedIcon />}
                            size="medium"
                          >
                            Enviar E-mail
                          </Button>
                        </a>
                      </Grid>
                      <Grid item>
                        <a
                          href="https://wa.me/+5511957055684"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button startIcon={<WhatsAppIcon />} size="medium">
                            Chamar no Whatsapp
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* right column */}
                  <Grid container item xs={12} sm={12} lg={4}>
                    <Grid
                      container
                      item
                      flexDirection="column"
                      // justifyContent="space-between"
                      gap={7}
                    >
                      <Grid
                        item
                        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                      >
                        <h6 className={styles.h6_footer}>Comunidade</h6>
                      </Grid>
                      <Grid
                        item
                        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                      >
                        <h5 className={styles.h6_footer}>Comunidade</h5>
                      </Grid>

                      <Grid container item rowSpacing={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <a
                            href="https://blog.connectabil.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button size="medium">Blog</Button>
                          </a>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <a
                            href="https://www.sejaumconnecter.connectabil.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button size="medium">Trabalhe Conosco </Button>
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* first's block end */}

              <Grid item xs={12} sm={12} lg={12}>
                <Divider />
              </Grid>

              {/* Second's block start */}
              <Grid
                container
                item
                lg={12}
                flexDirection={"row"}
                justifyContent="space-between"
                alignItems="center"
                rowGap={4}
              >
                {/* Social Link Block */}
                <Grid
                  container
                  item
                  lg
                  columnGap={3}
                  sx={{
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      lg: "flex-start",
                    },
                  }}
                >
                  <Grid item>
                    <a
                      href="https://www.facebook.com/connectabil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton
                        aria-label="facebook"
                        size="medium"
                        color="primary"
                      >
                        <FacebookIcon />
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://www.instagram.com/connectabil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton
                        aria-label="instagram"
                        size="medium"
                        color="primary"
                      >
                        <InstagramIcon />
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://br.linkedin.com/company/connectabil"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton
                        aria-label="linkedin"
                        size="medium"
                        color="primary"
                      >
                        <LinkedInIcon />
                      </IconButton>
                    </a>
                  </Grid>
                </Grid>

                {/* Copyrights block */}
                <Grid
                  container
                  item
                  lg={4}
                  rowSpacing={1}
                  sx={{
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      lg: "flex-end",
                    },
                    textAlign: { xs: "center", sm: "center", lg: "right" },
                  }}
                >
                  <Grid item>
                    <Logo className={styles.logo_footer} />
                  </Grid>
                  <Grid item>
                    <span className={`xs_body ${styles.copyright}`}>
                      &copy;2018 - {new Date().getFullYear()} Connectabil. Todos
                      os direitos reservados
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Second's block end */}
          </Grid>
        </Container>
      </Container>
    </footer>
  );
}

export default Footer;
