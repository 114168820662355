import { AccountCircle, BusinessRounded } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import IdentityImg from "../imgs/identity.png";

function chooseRole() {
  return (
    <Container>
      <Grid
        container
        paddingTop={15}
        paddingBottom={10}
        lg={12}
        sx={{ flexFlow: "wrap-reverse" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={5}
          paddingTop={{ xs: 7, sm: 7, md: 0 }}
          sx={{
            aspectRatio: "1/1",
            backgroundImage: `url(${IdentityImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></Grid>
        <Grid item lg={1}></Grid>
        <Grid container item lg={6} rowGap={7} justifyContent={"center"}>
          <Grid container item alignSelf={"end"} textAlign={"center"}>
            <h2 className="disableMarginBlock">Como você se identifica?</h2>
          </Grid>
          {/* DESKTOP */}
          <Grid
            container
            item
            flexDirection={"column"}
            rowGap={3}
            display={{ xs: "none", sm: "none", md: "flex" }}
          >
            <Grid item>
              {/* PROD LINK */}
              <Link to={"https://candidate.connectabil.com/auth2"}>
                {/* DEV LINK */}
                {/* <Link to={"https://www.candidate/auth"}> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  startIcon={<AccountCircle />}
                >
                  Sou um talento
                </Button>
              </Link>
            </Grid>
            <Grid item>
              {/* PROD LINK */}
              <Link to={"https://customer.connectabil.com/auth"}>
                {/* DEV LINK */}
                {/* <Link to={"https://customer-develop.vercel.app/auth"}> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  startIcon={<BusinessRounded />}
                >
                  Represento uma empresa
                </Button>
              </Link>
            </Grid>
          </Grid>
          {/* MOBILE */}
          <Grid
            container
            item
            flexDirection={"column"}
            rowGap={3}
            display={{ xs: "flex", sm: "flex", md: "none" }}
          >
            <Grid item>
              <Link to={"https://candidate.connectabil.com/auth2"}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  startIcon={<AccountCircle />}
                >
                  Sou um talento
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={"https://customer.connectabil.com/auth"}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  startIcon={<BusinessRounded />}
                >
                  Represento uma empresa
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default chooseRole;
