import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

export const HooksProvider = ({ children }: { children: React.ReactNode }) => {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_URL_BACKEND,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
